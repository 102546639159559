import { React, useEffect } from "react";
import * as Icon from "../../components/Icons";
import { Trans, useTranslation } from "react-i18next";
import Layout from "../../utils/layout/Layout.jsx";
import { useAppState } from "states/appState";
import { Navigate } from "react-router-dom";
import * as jose from "jose";
import { routes } from "utils/auth0/constants";

import * as amplitude from "@amplitude/analytics-browser";

const MailConfirm = () => {
  const { t, i18n } = useTranslation();
  const [appContext /*, setAppContext*/] = useAppState();

  useEffect(() => {
    amplitude.logEvent("pageView", {
      pageName: "/sso/profile/waitingEmailValidation",
      language: i18n.language,
    });
  }, []);

  useEffect(() => {
    document.title = t("metaTags.pagetitle.mailConfirm");

    // eslint-disable-next-line
  }, []);

  if (
    !appContext.session_token.validToken ||
    appContext.session_token.emailVerified ||
    !appContext.session_token.email
  ) {
    return <Navigate replace to={routes.Error} />;
  }

  function resendEmail(e) {
    e.preventDefault();
    let payload = {
      state: appContext.session_token.continueToken,
      action: "resend-verification-email",
    };

    const secret = new TextEncoder().encode(process.env.REACT_APP_SHARED_KEY);

    new jose.SignJWT(payload)
      .setProtectedHeader({ alg: "HS256", typ: "JWT" })
      .setIssuedAt()
      .setIssuer(appContext.session_token.issuer)
      .setSubject(appContext.session_token.subject)
      .setExpirationTime("2m")
      .sign(secret)
      .then((jwt) => {
        window.location.href =
          appContext.session_token.continueUri +
          "?state=" +
          appContext.session_token.continueToken +
          "&session_token=" +
          jwt;
      })
      .catch(() => {
        console.error("Failed to sign JWT token");
      });
  }

  function returnToLogin(e) {
    e.preventDefault();

    const payload = {
      state: appContext.session_token.continueToken,
      action: "complete-profile-after-email-verif",
    };

    const secret = new TextEncoder().encode(process.env.REACT_APP_SHARED_KEY);

    new jose.SignJWT(payload)
      .setProtectedHeader({ alg: "HS256", typ: "JWT" })
      .setIssuedAt()
      .setIssuer(appContext.session_token.issuer)
      .setSubject(appContext.session_token.subject)
      .setExpirationTime("2m")
      .sign(secret)
      .then((jwt) => {
        window.location.href =
          appContext.session_token.continueUri +
          "?state=" +
          appContext.session_token.continueToken +
          "&session_token=" +
          jwt;
      })
      .catch(() => {
        console.error("failed to sign JWT token");
      });
  }

  return (
    <>
      <Layout
        title={t("mail_confirm.page")}
        containerClass="Profile-content--flCenter"
        iconHeaderLeft="true"
      >
        {appContext.styles.logoUrl && (
          <img
            height="32"
            className="Profile-logo"
            src={appContext.styles.logoUrl}
            alt="logo"
          />
        )}
        {!appContext.styles.logoUrl && <br />}
        <div className="Profile-iconBackground">
          <Icon.Sablier className="Profile-iconSablier" />
        </div>
        <h2 className="Profile-title Profile-title--sub">
          {t("mail_confirm.title")}
        </h2>
        <p className="Profile-text">
          <Trans
            i18nKey="mail_confirm.text"
            values={{ mail: appContext.session_token.email }}
            components={{ bold: <strong /> }}
          ></Trans>
        </p>
        {appContext.session_token.verificationEmailSent && (
          <p
            style={{
              "padding-bottom": "1.3rem",
              color: "var(--color-secondary)",
              fontWeight: "bold",
            }}
          >
            {t("mail_confirm.verif")}
          </p>
        )}
        {!appContext.session_token.verificationEmailSent && (
          <button className="Button" onClick={resendEmail}>
            {t("mail_confirm.resend")}
          </button>
        )}
        {appContext.session_token.clientId &&
          appContext.session_token.redirectUri && (
            <a
              className="Button Button--outlined"
              href="#!"
              onClick={returnToLogin}
            >
              {t("mail_confirm.backToBtn")}
            </a>
          )}
      </Layout>
    </>
  );
};

export default MailConfirm;
