import Layout from "utils/layout/Layout.jsx";
import { useAppState } from "states/appState";
import * as Icon from "components/Icons";
import { Navigate } from "react-router-dom";
import { routes } from "utils/auth0/constants";
import { Trans, useTranslation } from "react-i18next";
import { React, useState, useEffect } from "react";
import Loader from "components/Loader/Index";
import { toast, Toaster } from "react-hot-toast";
import Toast from "../../components/Toast/Index";
import { setAccessTokenDspInfo } from "utils/auth0/common";
import * as amplitude from "@amplitude/analytics-browser";

import { getCurrentUserAsync } from "datas/user";

const PasswordReset = () => {
  const { i18n, t } = useTranslation();
  const [appContext, setAppContext] = useAppState();
  const [loader, setLoader] = useState(true);
  const [infos, setInfos] = useState({
    email: "",
  });

  useEffect(() => {
    amplitude.logEvent("pageView", {
      pageName: "/sso/profile/resetPassword",
      language: i18n.language,
    });
  }, []);

  useEffect(() => {
    if (appContext.access_token.rawToken) {
      getCurrentUserAsync({
        token: appContext.access_token.rawToken,
        language: i18n.language,
      })
        .then((user) => {
          setInfos(user);
          setAccessTokenDspInfo(setAppContext, user);
          setLoader(false);
        })
        .catch(() => console.error("Failed to get user info"));
    }

    document.title = t("metaTags.pagetitle.passwordReset");
  }, [appContext.access_token.rawToken]);

  if (!appContext.access_token.validToken) {
    return <Navigate replace to={routes.Error} />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);

    const payload = {
      client_id: appContext.access_token.client_id,
      email: infos.email,
      connection: "LFP",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + appContext.access_token.rawToken,
      },
      body: JSON.stringify(payload),
    };

    fetch(
      "https://" +
        process.env.REACT_APP_AUTH0_DOMAIN +
        "/dbconnections/change_password",
      requestOptions
    )
      .then((_) => {
        setLoader(false);
        toast(
          (toast) => <Toast text={t("profile.password.reset")} t={toast} />,
          {
            duration: 5000,
          }
        );
      })
      .catch(() => console.error("Failed to request password change"));
  };

  function redirectToOrigin(e) {
    e.preventDefault();
    window.location.replace(appContext.access_token.redirectUri);
  }

  return (
    <>
      <Layout
        title={t("profile.password.title")}
        containerClass="Profile-content--flCenter"
        iconHeaderRight="true"
      >
        <Toaster />
        {loader && <Loader />}
        {appContext.styles.logoUrl && (
          <img
            height="32"
            className="Profile-logo"
            src={appContext.styles.logoUrl}
            alt="logo"
          />
        )}
        {!appContext.styles.logoUrl && <br />}
        <div className="Profile-iconBackground">
          <Icon.Sablier className="Profile-iconSablier" />
        </div>
        <p className="Profile-text">
          <Trans
            i18nKey="profile.password.text"
            values={{ mail: infos.email }}
            components={{ bold: <strong /> }}
          ></Trans>
        </p>
        <button className="Button" onClick={handleSubmit}>
          {t("profile.password.send")}
        </button>
        {appContext.access_token.redirectUri && (
          <a
            className="Button Button--outlined"
            href="#!"
            onClick={redirectToOrigin}
          >
            {t("profile.password.return")}
          </a>
        )}
      </Layout>
    </>
  );
};

export default PasswordReset;
