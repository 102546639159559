import { React, useState, useEffect } from "react";
import Layout from "../../utils/layout/Layout.jsx";
import DropdownWithSearch from "../../components/FormElement/Dropdown/Dropdown.jsx";
import { getCompetitionTeams, groupedTeams } from "datas/teams";
import FormGroup from "../../components/FormElement/FormGroup";
import Input from "../../components/FormElement/Input";
import Label from "../../components/FormElement/Label";
import { useTranslation } from "react-i18next";
import { useAppState } from "states/appState";
import { Navigate } from "react-router-dom";
import { routes, validators } from "../../utils/auth0/constants";
import Loader from "../../components/Loader/Index";
import { toast, Toaster } from "react-hot-toast";
import Toast from "../../components/Toast/Index";
import { setAccessTokenDspInfo } from "utils/auth0/common";
import * as amplitude from "@amplitude/analytics-browser";

import { getCurrentUserAsync, updateCurrentUserAsync } from "datas/user";

let defaultAlias = "";

const EditProfile = () => {
  const [infos, setInfos] = useState({
    firstName: "",
    lastName: "",
    username: "",
    birthDate: "",
    mainFavoriteClubId: undefined,
    avatarUrl: undefined,
    optins: [],
  });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(true);
  const { i18n, t } = useTranslation();
  const [appContext, setAppContext] = useAppState();
  const [teams, setTeams] = useState({ L1: {}, L2: {} });

  useEffect(() => {
    amplitude.logEvent("pageView", {
      pageName: "/sso/profile/updateProfile",
      language: i18n.language,
    });
  }, []);

  useEffect(() => {
    if (appContext.access_token.rawToken) {
      getCurrentUserAsync({
        token: appContext.access_token.rawToken,
        language: i18n.language,
        includeOptins: true,
      })
        .then((user) => {
          setInfos(user);
          setAccessTokenDspInfo(setAppContext, user);
          defaultAlias = user.username;
          setLoader(false);
        })
        .catch((error) => console.error("Failed to get user info", error));
    }

    getCompetitionTeams().then((competitionTeams) => {
      // ajout de l'option "aucune" dans la liste des teams
      competitionTeams.L1.teams?.push({
        value: -1,
        label: t("profile.input.selectClub.noTeam"),
        image: "",
      });
      competitionTeams.L2.teams?.push({
        value: -2,
        label: t("profile.input.selectClub.noTeam"),
        image: "",
      });
      setTeams(competitionTeams);
    });

    document.title = t("metaTags.pagetitle.profileInfo");
  }, [appContext.access_token.rawToken]);

  if (!appContext.access_token.validToken) {
    return <Navigate replace to={routes.Error} />;
  }

  const validationRules = {
    default: {
      message: "Ce champ est requis",
    },
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "birthDate") {
      if (event.target.validity.rangeOverflow) {
        event.target.setCustomValidity(
          t("profile.input.birthdateOverflowError")
        );
      } else if (event.target.validity.rangeUnderflow) {
        event.target.setCustomValidity(
          t("profile.input.birthdateUnderflowError")
        );
      } else {
        event.target.setCustomValidity("");
      }
    }

    if (value.length !== 0) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }

    setInfos((values) => ({ ...values, [name]: value }));
  };

  const handleTeamChange = (data) => {
    if (data.value.length !== 0) {
      setErrors({
        ...errors,
        mainFavoriteClubId: "",
      });
    }

    setInfos((values) => ({ ...values, mainFavoriteClubId: data.value }));
  };

  const isAliasValid =
    new RegExp(validators.AliasRegex).test(infos.username) ||
    infos.username === defaultAlias;

  const isFormValid =
    (infos.firstName?.length ?? 0) !== 0 &&
    (infos.birthDate?.length ?? 0) !== 0 &&
    (infos.username?.length ?? 0) !== 0 &&
    !!infos.mainFavoriteClubId &&
    isAliasValid;

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(true);

    if (isFormValid) {
      setErrors({});

      updateCurrentUserAsync({
        token: appContext.access_token.rawToken,
        userProfile: infos,
      })
        .then((_) => {
          toast((toast) => <Toast text={t("profile.edit.save")} t={toast} />, {
            duration: 5000,
          });
          setLoader(false);
        })
        .catch(() => console.error("Failed to update user info"));
    } else {
      setErrors({
        firstName: !infos.firstName ? validationRules.default.message : "",
        birthDate: !infos.birthDate ? validationRules.default.message : "",
        username: !infos.username ? validationRules.default.message : "",
        mainFavoriteClubId: !infos.mainFavoriteClubId
          ? validationRules.default.message
          : "",
      });
    }
  };

  return (
    <Layout title={t("profile.edit.title")} iconHeaderRight="true">
      <Toaster />
      <form onSubmit={handleSubmit} className="FormElement">
        {loader && <Loader />}
        <DropdownWithSearch
          datas={groupedTeams(teams.L1, teams.L2)}
          placeholder={t("profile.input.selectClub.placeholder")}
          label={t("profile.input.selectClub.label")}
          id="mainFavoriteClubId"
          onChange={handleTeamChange}
          required={true}
          showError={errors.mainFavoriteClubId}
          // eslint-disable-next-line eqeqeq
          selectedOptions={teams.L1.teams
            ?.concat(teams.L2.teams)
            .find((t) => t.value === infos.mainFavoriteClubId)}
        />

        <FormGroup className={errors.firstName && "FormElement-invalid"}>
          <Input
            id="firstName"
            type="text"
            name="firstName"
            value={infos.firstName || ""}
            setStateParent={setInfos}
            onChange={handleChange}
            placeholder={t("profile.input.firstname")}
            required={true}
            maxLength={30}
          />
          <Label
            text={t("profile.input.firstname")}
            id="firstName"
            required="true"
            showError={errors.firstName}
          />
        </FormGroup>

        <FormGroup>
          <Input
            id="lastName"
            type="text"
            name="lastName"
            value={infos.lastName || ""}
            setStateParent={setInfos}
            onChange={handleChange}
            placeholder={t("profile.input.lastname")}
            maxLength={30}
          />
          <Label text={t("profile.input.lastname")} id="lastName" />
        </FormGroup>

        <FormGroup>
          <Input
            id="birthDate"
            type="date"
            name="birthDate"
            value={infos.birthDate?.substring(0, 10) || ""}
            setStateParent={setInfos}
            onChange={handleChange}
            placeholder={t("profile.input.birthdate")}
            required={true}
            min={validators.UserMinAgeShortDate}
            max={validators.UserMaxAgeShortDate}
          />
          <Label
            text={t("profile.input.birthdate")}
            id="birthDate"
            required="true"
            className="FormElement-label--top"
            showError={errors.birthDate}
          />
        </FormGroup>

        <FormGroup
          className={
            (errors.username || !isAliasValid) && "FormElement-invalid"
          }
        >
          <Input
            id="username"
            type="text"
            autoComplete="off"
            name="username"
            value={infos.username || ""}
            /* setStateParent={setInfos} */
            placeholder={t("profile.input.alias")}
            required={true}
            readOnly={true}
            hideReset={true}
            minLength={3}
            maxLength={16}
            /*onFocus={handleAliasFocus} onBlur={handleAliasBlur} onChange={handleChange}*/
          />
          <Label
            text={t("profile.input.alias")}
            id="username"
            required="true"
            showError={errors.username || !isAliasValid}
          />
          {!isAliasValid && (
            <span className="FormElement-error">
              {t("profile.error.pseudoNotValid")}
            </span>
          )}
        </FormGroup>

        <button type="submit" disabled={!isFormValid} className="Button">
          {t("profile.sendBtn")}
        </button>
      </form>
    </Layout>
  );
};

export default EditProfile;
