import "./CompleteProfile.scss";
import { useTranslation } from "react-i18next";
import { useAppState } from "states/appState";
import DropdownWithSearch from "../../components/FormElement/Dropdown/Dropdown.jsx";
import { Navigate } from "react-router-dom";
import { getCompetitionTeams, groupedTeams } from "datas/teams";
import Input from "components/FormElement/Input";
import Label from "../../components/FormElement/Label";
import FormGroup from "../../components/FormElement/FormGroup";
import * as jose from "jose";
import { React, useState, useEffect } from "react";
import Layout from "../../utils/layout/Layout.jsx";
import { routes, validators } from "../../utils/auth0/constants";
import { generateRandomAlias } from "../../utils/auth0/common";
import { defaultOptins, optinStatuses, optinTypes } from "../../datas/optins";
import Loader from "../../components/Loader/Index";
import * as amplitude from "@amplitude/analytics-browser";

let initAliasAlreadyInUse = "";
const signupOptins = defaultOptins.filter((optin) => optin.Signup);

function CompleteProfile() {
  const [appContext, setAppContext] = useAppState();
  const { t, i18n } = useTranslation();
  const [errors, setErrors] = useState({});
  const [aliasEditionEnabled, setAliasEditionEnabled] = useState(true);
  const [partnerOptinEnabled, setPartnerOptinEnabled] = useState(false);
  const [defaultAlias, setDefaultAlias] = useState(generateRandomAlias());
  const [teams, setTeams] = useState({ L1: {}, L2: {} });
  const [loading, setLoading] = useState(true);
  const [selectedClub, setSelectedClub] = useState({});
  const [optinClubState, setOptinClubState] = useState(false);

  useEffect(() => {
    amplitude.logEvent("pageView", {
      pageName: "/sso/profile/completeProfile",
      language: i18n.language,
    });
  }, []);

  useEffect(() => {
    if (!appContext.session_token.alias?.trim()) {
      setAppContext((values) => ({
        ...values,
        session_token: { ...values.session_token, alias: defaultAlias },
      }));
    } else {
      setDefaultAlias(appContext.session_token.alias);
      if (!appContext.session_token.aliasAlreadyUsed) {
        setAliasEditionEnabled(false);
      }
    }

    if (
      appContext.session_token.optins &&
      appContext.session_token.optins.length > 0
    ) {
      let optinLFP = true;
      signupOptins.forEach((signupOptin) => {
        if (
          !appContext.session_token.optins.some(
            (optin) => optin.optinType === signupOptin.Value && optin.status
          )
        ) {
          optinLFP = false;
        }
      });
      if (optinLFP) {
        setAppContext((values) => ({
          ...values,
          session_token: { ...values.session_token, optinLFP: true },
        }));
      }
    }

    const optinPartner = appContext.session_token.optins?.some(
      (optin) =>
        optin.optinType === optinTypes.NEWSLETTER_PARTNERS && optin.status
    );
    if (optinPartner) {
      setAppContext((values) => ({
        ...values,
        session_token: { ...values.session_token, optinPartner: true },
      }));
    } else {
      setPartnerOptinEnabled(appContext.session_token.enablePartner);
    }

    (async () => {
      const competitionTeams = await getCompetitionTeams();
      // ajout de l'option "aucune" dans la liste des teams
      competitionTeams.L1.teams?.push({
        value: -1,
        label: t("profile.input.selectClub.noTeam"),
        image: "",
      });
      competitionTeams.L2.teams?.push({
        value: -2,
        label: t("profile.input.selectClub.noTeam"),
        image: "",
      });
      setSelectedClub(
        competitionTeams.L1.teams
          ?.concat(competitionTeams.L2.teams)
          .find(
            (t) => t.value === Number(appContext.session_token.favoriteTeam)
          )
      );
      setTeams(competitionTeams);
      setLoading(false);
    })();

    document.title = t("metaTags.pagetitle.completeProfile");

    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }

    if (name === "birthdate") {
      if (event.target.validity.rangeOverflow) {
        event.target.setCustomValidity(
          t("profile.input.birthdateOverflowError")
        );
      } else if (event.target.validity.rangeUnderflow) {
        event.target.setCustomValidity(
          t("profile.input.birthdateUnderflowError")
        );
      } else {
        event.target.setCustomValidity("");
      }
    }

    if (value.length !== 0) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }

    setAppContext((values) => ({
      ...values,
      session_token: { ...values.session_token, [name]: value },
    }));
  };

  const handleTeamChange = (data) => {
    if (data.value.length !== 0) {
      setErrors({
        ...errors,
        // eslint-disable-next-line
        ["favoriteTeam"]: "",
      });
    }
    setSelectedClub(
      teams.L1.teams?.concat(teams.L2.teams).find((t) => t.value === data.value)
    );
    // eslint-disable-next-line
    setAppContext((values) => ({
      ...values,
      session_token: { ...values.session_token, ["favoriteTeam"]: data.value },
    }));
  };

  const handleOptinClubChange = (event) => {
    setOptinClubState(event.target.checked);
  };

  if (!initAliasAlreadyInUse && appContext.session_token.aliasAlreadyUsed) {
    initAliasAlreadyInUse = appContext.session_token.alias;
  }
  const isAliasAlreadyUsed =
    appContext.session_token.aliasAlreadyUsed &&
    appContext.session_token.alias === initAliasAlreadyInUse;
  const isAliasValid =
    new RegExp(validators.AliasRegex).test(appContext.session_token.alias) ||
    appContext.session_token.alias === defaultAlias;

  const isFormValid =
    (appContext.session_token.firstname?.trim()?.length ?? 0) !== 0 &&
    (appContext.session_token.birthdate?.trim()?.length ?? 0) !== 0 &&
    (appContext.session_token.alias?.trim()?.length ?? 0) !== 0 &&
    !!appContext.session_token.favoriteTeam &&
    !isAliasAlreadyUsed &&
    isAliasValid;

  const handleAliasFocus = (event) => {
    if (event.target.value === defaultAlias) {
      setAppContext((values) => ({
        ...values,
        session_token: { ...values.session_token, alias: "" },
      }));
    }
  };

  const handleAliasBlur = (event) => {
    if (!event.target.value?.trim()) {
      setAppContext((values) => ({
        ...values,
        session_token: { ...values.session_token, alias: defaultAlias },
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid) {
      setErrors({});

      const optins = appContext.session_token.optins || [];
      const optinsByType = optins.reduce((acc, optin) => {
        acc[optin.optinType] = optin;
        return acc;
      }, {});

      signupOptins.forEach((optinSignup) => {
        const optin = optinsByType[optinSignup.Value];
        if (optin) {
          optin.status = appContext.session_token.optinLFP
            ? optinStatuses.ENABLED
            : optinStatuses.DISABLED;
        } else {
          optins.push({
            optinType: optinSignup.Value,
            status: appContext.session_token.optinLFP
              ? optinStatuses.ENABLED
              : optinStatuses.DISABLED,
          });
        }
      });

      const optinPartner = optinsByType[optinTypes.NEWSLETTER_PARTNERS];
      if (optinPartner) {
        optinPartner.status = appContext.session_token.optinPartner
          ? optinStatuses.ENABLED
          : optinStatuses.DISABLED;
      } else {
        optins.push({
          optinType: optinTypes.NEWSLETTER_PARTNERS,
          status: appContext.session_token.optinPartner
            ? optinStatuses.ENABLED
            : optinStatuses.DISABLED,
        });
      }

      if (selectedClub?.value > 0) {
        const optinType = `${optinTypes.FAVORITE_CLUB_PREFIX}_${selectedClub.value}`;
        const optinClub = optinsByType[optinType];
        if (optinClub) {
          optinClub.status = optinClubState
            ? optinStatuses.ENABLED
            : optinStatuses.DISABLED;
        } else {
          if (optinClubState) {
            optins.push({
              optinType: optinType,
              status: optinClubState
                ? optinStatuses.ENABLED
                : optinStatuses.DISABLED,
            });
          }
        }
      }

      const payload = {
        state: appContext.session_token.continueToken,
        firstname: appContext.session_token.firstname,
        lastname: appContext.session_token.lastname,
        birthdate: appContext.session_token.birthdate,
        avatarUrl: appContext.session_token.avatarUrl,
        favoriteTeam: appContext.session_token.favoriteTeam,
        alias: appContext.session_token.alias,
        optins,
        action: "complete-profile",
      };

      const secret = new TextEncoder().encode(process.env.REACT_APP_SHARED_KEY);

      new jose.SignJWT(payload)
        .setProtectedHeader({ alg: "HS256", typ: "JWT" })
        .setIssuedAt()
        .setIssuer(appContext.session_token.issuer)
        .setSubject(appContext.session_token.subject)
        .setExpirationTime("2m")
        .sign(secret)
        .then((jwt) => {
          window.location.href =
            appContext.session_token.continueUri +
            "?state=" +
            appContext.session_token.continueToken +
            "&session_token=" +
            jwt;
        })
        .catch(() => {
          console.error("failed to sign JWT token");
        });
    } else {
      setErrors({
        firstname: !appContext.session_token.firstname
          ? t("formValidation.default.message")
          : "",
        birthdate: !appContext.session_token.birthdate
          ? t("formValidation.default.message")
          : "",
        alias: !appContext.session_token.alias
          ? t("formValidation.default.message")
          : "",
        favoriteTeam: !appContext.session_token.favoriteTeam
          ? t("formValidation.default.message")
          : "",
      });
    }
  };

  if (
    !appContext.session_token.validToken ||
    !appContext.session_token.emailVerified ||
    !appContext.session_token.email
  ) {
    return (
      <Navigate
        replace
        to={routes.Error}
        state={{ error_code: "error.custom.text" }}
      />
    );
  }

  return (
    <Layout title={t("profile.title")} iconHeaderRight="true">
      <form onSubmit={handleSubmit} className="FormElement">
        {loading && <Loader />}
        <DropdownWithSearch
          datas={groupedTeams(teams.L1, teams.L2)}
          placeholder={t("profile.input.selectClub.placeholder")}
          label={t("profile.input.selectClub.label")}
          id="favoriteTeam"
          onChange={handleTeamChange}
          required={true}
          showError={errors.favoriteTeam}
          // eslint-disable-next-line eqeqeq
          selectedOptions={
            appContext.session_token.favoriteTeam
              ? teams.L1.teams
                  ?.concat(teams.L2.teams)
                  .find(
                    (t) => t.value === appContext.session_token.favoriteTeam
                  )
              : ""
          }
        />

        <FormGroup className={errors.firstname && "FormElement-invalid"}>
          <Input
            id="firstname"
            type="text"
            name="firstname"
            value={appContext.session_token.firstname || ""}
            setStateParent={setAppContext}
            neestedObject="session_token"
            onChange={handleChange}
            placeholder={t("profile.input.firstname")}
            required={true}
            maxLength={30}
          />
          <Label
            text={t("profile.input.firstname")}
            id="firstname"
            required="true"
            showError={errors.firstname}
          />
        </FormGroup>

        <FormGroup>
          <Input
            id="birthdate"
            type="date"
            name="birthdate"
            value={appContext.session_token.birthdate?.substring(0, 10) || ""}
            onChange={handleChange}
            placeholder={t("profile.input.birthdate")}
            required={true}
            min={validators.UserMinAgeShortDate}
            max={validators.UserMaxAgeShortDate}
          />
          <Label
            text={t("profile.input.birthdate")}
            id="birthdate"
            required="true"
            className="FormElement-label--top"
            showError={errors.birthdate}
          />
        </FormGroup>

        <FormGroup
          className={
            (errors.alias || isAliasAlreadyUsed || !isAliasValid) &&
            "FormElement-invalid"
          }
        >
          {aliasEditionEnabled && (
            <Input
              id="alias"
              type="text"
              name="alias"
              autoComplete="off"
              value={appContext.session_token.alias || ""}
              setStateParent={setAppContext}
              neestedObject="session_token"
              onChange={handleChange}
              placeholder={t("profile.input.alias")}
              required={true}
              onFocus={handleAliasFocus}
              onBlur={handleAliasBlur}
              minLength={3}
              maxLength={16}
            />
          )}
          {!aliasEditionEnabled && (
            <Input
              id="alias"
              type="text"
              name="alias"
              autoComplete="off"
              value={appContext.session_token.alias || ""}
              readOnly={true}
              placeholder={t("profile.input.alias")}
              required={true}
              hideReset={true}
              minLength={3}
              maxLength={16}
            />
          )}
          <Label
            text={t("profile.input.alias")}
            id="alias"
            required="true"
            showError={errors.alias || isAliasAlreadyUsed || !isAliasValid}
          />
          {isAliasAlreadyUsed && (
            <span className="FormElement-error">
              {t("profile.error.pseudoAlreadyUsed")}
            </span>
          )}
          {!isAliasValid && (
            <span className="FormElement-error">
              {t("profile.error.pseudoNotValid")}
            </span>
          )}
        </FormGroup>

        <div>
          <FormGroup>
            <Input
              id="optinLFP"
              type="checkbox"
              name="optinLFP"
              value={appContext.session_token.optinLFP || false}
              onChange={handleChange}
              checked={appContext.session_token.optinLFP || false}
            />
            <Label text={t("profile.input.optinLFP")} id="optinLFP" />
          </FormGroup>

          {selectedClub && selectedClub?.value > 0 && (
            <FormGroup>
              <Input
                id="optinClub"
                type="checkbox"
                name="optinClub"
                onChange={handleOptinClubChange}
                checked={optinClubState || false}
              />
              <Label
                rawHtml={t("profile.input.optinClub").replace(
                  "{{club}}",
                  selectedClub?.label
                )}
                id="optinClub"
              />
            </FormGroup>
          )}

          {partnerOptinEnabled && (
            <FormGroup>
              <Input
                id="optinPartner"
                type="checkbox"
                name="optinPartner"
                value={appContext.session_token.optinPartner || false}
                onChange={handleChange}
                checked={appContext.session_token.optinPartner || false}
              />
              <Label
                rawHtml={t("profile.input.optinPartner")}
                id="optinPartner"
              />
            </FormGroup>
          )}
        </div>

        <button type="submit" disabled={!isFormValid} className="Button">
          {t("profile.sendBtn")}
        </button>
      </form>
    </Layout>
  );
}

export default CompleteProfile;
