import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import Loader from "../../components/Loader/Index";
import Layout from "../../utils/layout/Layout.jsx";
import { routes } from "../../utils/auth0/constants";
import UnsubscriptionConfirmed from "./UnsubscriptionConfirmed.jsx";
import { unsubscribeUserOptinsAsync } from "../../datas/optins";
import * as amplitude from "@amplitude/analytics-browser";

const queryString = window.location.search;
const params = new URLSearchParams(queryString);
const userId = params.get("id");
const optinType = params.get("idConsent");

function UnsubscribeNewsletter() {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [showConfirmationLayout, setShowConfirmationLayout] = useState(false);

  useEffect(() => {
    amplitude.logEvent("pageView", {
      pageName: "/sso/profile/unsubscribe",
      language: i18n.language,
    });
  }, []);

  if (!userId || !optinType) {
    return <Navigate replace to={routes.Error} />;
  }

  const handleClick = () => {
    setLoader(true);

    unsubscribeUserOptinsAsync(userId, optinType)
      .then(() => {
        setTimeout(() => {
          setLoader(false);
          setShowConfirmationLayout(true);
        }, 500);
      })
      .catch(() => {
        setLoader(false);
        console.error("Failed to update user consents");
      });
  };

  function UnsubscribeNewsletterLayoutSwitch() {
    if (showConfirmationLayout) {
      return <UnsubscriptionConfirmed />;
    } else {
      return (
        <Layout
          title={t("unsubscribeNewsletter.title")}
          containerClass="Profile-content--flCenter Profile-content--text"
          iconHeaderRight="true"
          iconRedirectURL={"https://www.ligue1.fr"}
        >
          {loader && <Loader />}
          <p className="Profile-text">
            <Trans
              i18nKey="unsubscribeNewsletter.text"
              components={{ return: <br /> }}
            ></Trans>
          </p>
          <div className={"Profile-actions"}>
            <a className="Button Button--outlined" href="https://www.ligue1.fr">
              {t("unsubscribeNewsletter.cancelBtn")}
            </a>
            <button className="Button" onClick={handleClick}>
              {t("unsubscribeNewsletter.unsubscribeBtn")}
            </button>
          </div>
        </Layout>
      );
    }
  }

  return <UnsubscribeNewsletterLayoutSwitch />;
}

export default UnsubscribeNewsletter;
