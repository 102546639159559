import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import HttpApi from "i18next-http-backend";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .use(HttpApi)
  .init({
    backend: {
      loadPath:
        process.env.REACT_APP_STATIC_RESOURCES_BASE_URL +
        "/locales/{{lng}}/{{ns}}.json",
      addPath:
        process.env.REACT_APP_STATIC_RESOURCES_BASE_URL +
        "/locales/add/{{lng}}/{{ns}}",
      crossDomain: true,
      backendOptions: {
        expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
      },
    },
    fallbackLng: (code) => {
      if (code) {
        return code.slice(0, 2);
      }
      return "en";
    },
  });

i18next.on("languageChanged", (lng) => {
  document.documentElement.setAttribute("lang", i18next.resolvedLanguage);
});
