import { createContext, useState, useContext, useEffect } from "react";
import * as jose from "jose";
import { defaultAppState } from "utils/auth0/constants";
import Loader from "components/Loader/Index";

export const AppStateContext = createContext({});
const appState = defaultAppState;

export function AppProvider({ children }) {
  const [value, setValue] = useState(appState);
  const [loaded, setLoaded] = useState(false);

  const decodeTokens = async () => {
    const searchParams = new URLSearchParams(document.location.search);
    const continueToken = searchParams.get("state");
    const sessionToken = getToken("session_token");
    const accessToken = getToken("access_token");

    if (sessionToken && continueToken) {
      await decodeSessionToken(
        sessionToken,
        continueToken,
        searchParams,
        setValue
      );
    } else if (accessToken) {
      await decodeAccessToken(accessToken, searchParams, setValue);
    }

    setLoaded(true);
  };

  useEffect(() => {
    saveReferrer();
    decodeTokens();
  }, []);

  return (
    <AppStateContext.Provider value={[value, setValue]}>
      {!loaded && <Loader />}
      {loaded && children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppProvider");
  }
  return context;
}

async function decodeSessionToken(
  sessionToken,
  continueToken,
  searchParams,
  setAppStateValue
) {
  const key = new TextEncoder().encode(process.env.REACT_APP_SHARED_KEY);
  try {
    const amplitudeId = searchParams.get("ampId");
    const deviceAmplitudeId = searchParams.get("devAmpId");
    const { payload /*, protectedHeader*/ } = await jose.jwtVerify(
      sessionToken,
      key,
      {
        issuer: [
          process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN,
          process.env.REACT_APP_AUTH0_DOMAIN,
        ],
        clockTolerance: process.env.REACT_APP_CLOCKTOLERANCE,
      }
    );

    if (payload) {
      appState.init = true;
      appState.amplitudeId = amplitudeId || payload.amplitude;
      appState.deviceAmplitudeId = deviceAmplitudeId || payload.amplitudeId;
      appState.styles.colorPrimary =
        payload.primaryColor ?? appState.styles.colorPrimary;
      appState.styles.colorSecondary =
        payload.secondaryColor ?? appState.styles.colorSecondary;
      appState.styles.buttonTextColor =
        payload.buttonTextColor ?? appState.styles.buttonTextColor;
      appState.styles.colorText =
        payload.textColor ?? appState.styles.colorText;
      appState.styles.colorTextFilled =
        payload.textColorFilled ?? appState.styles.colorTextFilled;
      appState.styles.colorFocus =
        payload.focusColor ?? appState.styles.colorFocus;
      appState.styles.colorBackgroundBloc =
        payload.socialBackgroundColor ?? appState.styles.colorBackgroundBloc;
      appState.styles.colorBackground =
        payload.widgetBackgroundColor ?? appState.styles.colorBackground;
      appState.styles.colorBackgroundBloc2 =
        payload.selectBackgroundColor ?? appState.styles.colorBackgroundBloc2;
      appState.styles.logoUrl = payload.logoUrl ?? appState.styles.logoUrl;
      appState.styles.favIcon = payload.favicon ?? appState.styles.favIcon;
      appState.styles.font = payload.font;
      appState.styles.fontSecondary = payload.fontSecondary;
      appState.styles.colorError =
        payload.errorColor ?? appState.styles.colorError;
      appState.styles.backgroundImage =
        payload.backgroundImage ?? appState.styles.backgroundImage;
      appState.session_token.email = payload.email;
      appState.session_token.emailVerified = payload.emailVerified ?? false;
      appState.session_token.verificationEmailSent =
        payload.verificationEmailSent ?? false;
      appState.session_token.validToken = true;
      appState.session_token.continueToken = continueToken;
      appState.session_token.continueUri = payload.continue_uri;
      appState.session_token.sessionToken = sessionToken;
      appState.session_token.issuer = payload.iss;
      appState.session_token.subject = payload.sub;
      appState.session_token.clientId = payload.clientId;
      appState.session_token.redirectUri = payload.redirectUri;
      appState.session_token.favoriteTeam = payload.favoriteTeam;
      appState.session_token.firstname = payload.firstname;
      appState.session_token.birthdate = payload.birthdate;
      appState.session_token.lastname = payload.lastname;
      appState.session_token.alias = payload.alias;
      appState.session_token.aliasAlreadyUsed = payload.aliasAlreadyUsed;
      appState.session_token.avatarUrl = payload.avatarUrl;
      appState.session_token.dspId = payload.dspId;
      appState.session_token.optins = payload.optins;
      appState.session_token.amplitude = payload.amplitude;
      appState.session_token.amplitudeId = payload.amplitudeId;
      appState.session_token.adjust = payload.adjust;
      appState.session_token.adjustId = payload.adjustId;
      appState.session_token.connection = payload.connection;
      appState.session_token.clientName = payload.clientName;
      appState.session_token.enablePartner = payload.enablePartner;
      setAppStateValue(appState);
    }
  } catch (error) {
    console.error("Failed to decode session token", error);
  }
}

async function decodeAccessToken(accessToken, searchParams, setAppStateValue) {
  const amplitudeId = searchParams.get("ampId");
  const deviceAmplitudeId = searchParams.get("devAmpId");

  const JWKS = jose.createRemoteJWKSet(
    new URL(
      "https://" + process.env.REACT_APP_AUTH0_DOMAIN + "/.well-known/jwks.json"
    ),
    { timeoutDuration: 30000 }
  );

  try {
    const { payload /*, protectedHeader*/ } = await jose.jwtVerify(
      accessToken,
      JWKS,
      {
        issuer: [
          "https://" + process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN + "/",
          "https://" + process.env.REACT_APP_AUTH0_DOMAIN + "/",
        ],
      }
    );

    if (payload) {
      appState.init = true;
      appState.amplitudeId = amplitudeId;
      appState.deviceAmplitudeId = deviceAmplitudeId;
      appState.access_token.clientId = payload.azp;
      appState.access_token.validToken = true;
      appState.access_token.userId = payload.sub;
      appState.access_token.rawToken = accessToken;
      appState.access_token.dspClientId =
        payload[
          "https://" +
            process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN +
            "/dsp_client_id"
        ];
      appState.access_token.origin =
        payload[
          "https://" + process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN + "/origin"
        ];
      appState.access_token.clientName =
        payload[
          "https://" +
            process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN +
            "/client_name"
        ];
      appState.access_token.redirectUri = appState.access_token.origin
        ? "https://" + appState.access_token.origin
        : "";
      const customizationClaim =
        payload[
          "https://" +
            process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN +
            "/customization"
        ];
      if (customizationClaim) {
        const custoParams = new URLSearchParams(customizationClaim);
        if (custoParams.has("background")) {
          appState.styles.backgroundImage = custoParams.get("background");
        }
        if (custoParams.has("logo")) {
          appState.styles.logoUrl = custoParams.get("logo");
        }
        if (custoParams.has("primaryColor")) {
          appState.styles.colorPrimary = custoParams.get("primaryColor");
        }
        if (custoParams.has("secondaryColor")) {
          appState.styles.colorSecondary = custoParams.get("secondaryColor");
        }
        if (custoParams.has("buttonColor")) {
          appState.styles.buttonTextColor = custoParams.get("buttonColor");
        }
        if (custoParams.has("focusColor")) {
          appState.styles.colorFocus = custoParams.get("focusColor");
        }
        if (custoParams.has("textColor")) {
          appState.styles.colorText = custoParams.get("textColor");
        }
        if (custoParams.has("textColorFilled")) {
          appState.styles.colorTextFilled = custoParams.get("textColorFilled");
        }
        if (custoParams.has("widgetBackgroundColor")) {
          appState.styles.colorBackground = custoParams.get(
            "widgetBackgroundColor"
          );
        }
        if (custoParams.has("socialBackgroundColor")) {
          appState.styles.colorBackgroundBloc = custoParams.get(
            "socialBackgroundColor"
          );
        }
        if (custoParams.has("selectBackgroundColor")) {
          appState.styles.colorBackgroundBloc2 = custoParams.get(
            "selectBackgroundColor"
          );
        }
        if (custoParams.has("font")) {
          appState.styles.font = custoParams.get("font");
        }
        if (custoParams.has("fontSecondary")) {
          appState.styles.fontSecondary = custoParams.get("fontSecondary");
        }
        if (custoParams.has("theme")) {
          appState.styles.theme = custoParams.get("theme");
        }
        if (custoParams.has("amplitude")) {
          appState.session_token.amplitude = custoParams.get("amplitude");
        }
        if (custoParams.has("adjust")) {
          appState.session_token.adjust = custoParams.get("adjust");
        }
      }
    }

    let redirectUri = searchParams.get("redirect_uri");
    if (!redirectUri) {
      redirectUri = getReferrer();
    }
    if (redirectUri) {
      appState.access_token.redirectUri = redirectUri;
    }

    let cancelUri = searchParams.get("cancel_uri");
    if (cancelUri) {
      appState.access_token.cancelUri = cancelUri;
    }

    setAppStateValue(appState);
  } catch (error) {
    console.error("Failed to decode access token");
  }
}

function getToken(name) {
  const searchParams = new URLSearchParams(document.location.search);
  let sessionToken = searchParams.get(name);
  let isHashParam = false;

  if (!sessionToken?.trim() && window.location.hash) {
    const hashParams = new URLSearchParams(window.location.hash.split("#")[1]);
    sessionToken = hashParams.get(name);
    isHashParam = true;
  }

  if (sessionToken?.trim()) {
    try {
      window.sessionStorage.setItem(name, sessionToken);
      searchParams.delete(name);
      var querystring = searchParams.toString();

      const newLocation =
        window.location.pathname + (querystring ? "?" + querystring : "");
      if (typeof window.history.replaceState === "function" && isHashParam) {
        window.location.replace("#");
        window.history.replaceState({}, "", newLocation);
      } else {
        window.location.replace(newLocation);
      }
    } catch (e) {
      console.error("session storage is not enabled");
    }
  } else {
    try {
      sessionToken = window.sessionStorage.getItem(name);
    } catch (e) {
      console.error("session storage is not enabled");
    }
  }
  return sessionToken;
}

function getReferrer() {
  let referrer;
  try {
    referrer = window.sessionStorage.getItem("referrer");
  } catch (e) {}

  if (!referrer?.trim()) {
    if (
      document.referrer?.trim() &&
      !document.referrer?.includes(document.location.hostname)
    ) {
      referrer = document.referrer;
    }
  }

  return referrer;
}

function saveReferrer() {
  let referrer = document.referrer?.trim();
  if (referrer && !referrer.includes(document.location.hostname)) {
    try {
      window.sessionStorage.setItem("referrer", referrer);
    } catch (e) {}
  }
}
