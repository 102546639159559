const routes = {
  Home: "/",
  Error: "/error",
  CompleteProfile: "/complete-profile",
  ProfileInfo: "/profile/info",
  ProfileOptin: "/profile/optin",
  DeleteProfile: "/profile/delete",
  VerifyEmail: "/verify-email",
  MissingEmail: "/missing-email",
  PasswordReset: "/profile/password-reset",
  UnsubscribeNewsletter: "/unsubscribe",
};

const validators = {
  UserMinAgeShortDate: "1900-01-01",
  UserMaxAgeShortDate: new Date(
    new Date().setFullYear(new Date().getFullYear() - 16)
  )
    .toISOString()
    .slice(0, 10),
  AliasRegex: "^[a-zA-Z0-9_-]{3,16}$",
};

const dspProperties = {
  PreferredTeamKey: "PreferredTeam",
  ExtendedPropertiesKey: "ExtendedProperties",
};

const dspExtendedPropertiesList = [
  "FanNumber",
  "PreferredTeam",
  "PreferredPlayers",
  "FacebookUserId",
  "TwitterUserId",
  "GoogleUserId",
  "OtherAddress",
  "SecondContactEmail",
  "TvSubscription",
  "StadiumSubscription",
  "SourceCreationID",
  "JeuxConcours",
  "SocialIdentityProvider",
  "ProviderCreationDate",
  "ProviderUpdatedDate",
  "AuthorizedExternalPartner1",
  "AuthorizedExternalPartner2",
  "AuthorizedExternalPartner3",
  "AuthorizedExternalPartner4",
  "AuthorizedExternalPartner5",
  "AuthorizedExternalPartners",
  "NoSendDataToClubs",
  "NoSendDataToTicketing",
  "NoSendDataToUnfp",
  "SignUpReferrerPartner",
  "SignUpReferrerSite",
];

const dspAttributesList = [
  "Active Directory",
  "Update Email Verification",
  "Profile Avatar",
  "External Invitation",
  "Fan Tracking",
  "Disable Fan",
  "Fan Identifier",
  "name",
  "avatarname",
  "avatarthumbnailname",
  "alias",
  "email",
  "surname",
  "secondname",
  "birthdate",
  "mobilenumber",
  "homenumber",
  "address",
  "documentnumber",
  "zip",
];

const styles = {
  default: {
    colorPrimary: "#085FFF",
    colorSecondary: "#FF7FDE",
    buttonTextColor: "#FFFFFF",
    colorError: "#E81E29",
    colorBackground: "#161616",
    colorBackgroundBloc: "#262624",
    colorBackgroundBloc2: "#444441",
    colorText: "#A7A6A1",
    colorFocus: "#F7F7F6",
    colorTextFilled: "#FEF1FB",
    backgroundImage:
      "https://profile.ligue1.fr/static-assets/Images/bg/BG-MA-L1.jpg",
    favIcon:
      "https://profile.ligue1.fr/static-assets/Images/favicon/LFP-favicon.jpg",
    logoUrl: "https://profile.ligue1.fr/static-assets/Images/LFP.jpg",
  },
};

const defaultAppState = {
  init: false,
  styles: {
    font: "",
    fontSecondary: "",
    theme: "",
    ...styles.default,
  },
  amplitudeId: undefined,
  deviceAmplitudeId: undefined,
  session_token: {
    avatarUrl: undefined,
    lastname: "",
    firstname: "",
    email: "",
    emailVerified: false,
    verificationEmailSent: false,
    continueToken: "",
    continueUri: "",
    sessionToken: "",
    favoriteTeam: "",
    birthdate: "",
    alias: "",
    aliasAlreadyUsed: false,
    optinLFP: false,
    optinPartner: false,
    issuer: "",
    subject: "",
    validToken: false,
    clientId: "",
    redirectUri: "",
    connection: "",
    dspId: "",
    optins: [{}],
    amplitude: "",
    adjust: "",
  },
  access_token: {
    clientId: "",
    validToken: false,
    userId: "",
    rawToken: "",
    dspClientId: "",
    origin: "",
    redirectUri: "",
    dspId: "",
    favoriteTeam: "",
    clientName: "",
  },
};

export {
  routes,
  validators,
  dspProperties,
  styles,
  dspExtendedPropertiesList,
  dspAttributesList,
  defaultAppState,
};
